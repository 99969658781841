<template>
  <div class="container">
    <div class="section section__left">
      <div class="background__img"></div>
      <div>
        <!-- <span class="title">
          <h2>Bienvenido a Elha</h2>
        </span>
        <span class="separator"></span>
        <p>
          Ingresa y analisa todos los resultados obtenidos en tus encuestas de
          clima laboral y engagement.
        </p> -->

        <svg
          viewBox="0 0 120 120"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="60" cy="60" r="50" fill="white" />
          <circle cx="60" cy="60" r="30" />
        </svg>
      </div>
    </div>
    <ValidationObserver
      ref="form"
      tag="div"
      v-slot="{ handleSubmit }"
      class="section section__right"
    >
      <logo class="logo" color="black" size="sm" />
      <form @submit.prevent="handleSubmit(handleLogin)" class="form-container">
        <span class="title">
          <h2>Bienvenido a Elha</h2>
        </span>

        <span class="separator"></span>

        <span>
          <custom-input
            v-model="email"
            vid="email"
            id="email"
            type="email"
            label="Correo"
            rules="required"
          />
          <custom-input
            v-model="password"
            vid="password"
            id="password"
            type="password"
            label="Contraseña"
            rules="required"
          />

          <custom-button type="submit" variant="primary" :disabled="loading">
            {{ loading ? 'Cargando...' : 'Iniciar Sesión' }}
          </custom-button>
        </span>
        <div class="logos">
          <a href="https://www.facebook.com/profile.php?id=100076061235857">
            <img src="../assets/logos/facebook-logo.svg" alt="" />
          </a>
          <a href="https://www.instagram.com/elhadigital/">
            <img src="../assets/logos/instagram-logo.svg" alt="" />
          </a>
          <a href="https://www.linkedin.com/company/elhadigital/">
            <img src="../assets/logos/linkedin-logo.svg" alt="" />
          </a>
        </div>
      </form>
      <p>Ingresa y analisa todos los resultados obtenidos en tus encuestas de clima laboral y engagement.</p>

      <svg
        viewBox="0 0 120 120"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="70" height="70" rx="15" ry="15" />
        <rect
          width="45"
          height="45"
          fill="white"
          x="14"
          y="12"
          rx="10"
          ry="10"
        />
      </svg>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';

import CustomButton from '../components/CustomButton.vue';
import CustomInput from '../components/CustomInput.vue';
import Logo from '../components/Logo.vue';

export default {
  name: 'Login',

  components: {
    CustomButton,
    CustomInput,
    ValidationObserver,
    Logo,
  },

  data() {
    return {
      email: '',
      password: '',
      loading: false,
    };
  },

  computed: {
    ...mapState(['userProfile']),
  },

  methods: {
    ...mapActions(['login']),

    handleLogin() {
      this.loading = true;

      this.login({
        email: `${this.email}`,
        password: this.password,
      }).catch((e) => {
        console.log(e);
        this.loading = false;
        this.setErrorMessage(e);
      });
    },

    setErrorMessage(error) {
      console.log(error);
      switch (error.code) {
        case 'auth/wrong-password':
          this.$refs.form.setErrors({
            password: ['La contraseña ingresada es incorrecta.'],
          });
          break;

        case 'auth/user-not-found':
          this.$refs.form.setErrors({
            email: ['El usuario ingresado no fue encontrado.'],
          });
          break;

        case 'auth/too-many-requests':
          this.$refs.form.setErrors({
            password: [
              'Se han producido demasiados intentos fallidos, por favor inténtelo nuevamente más tarde.',
            ],
          });
          break;

        default:
          this.$refs.form.setErrors({
            password: ['Ocurrio un error, por favor inténtalo de nuevo.'],
          });
          break;
      }
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.55rem;
}
.logos {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}
.logos img {
  height: 1.15rem;
}
.background__img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  /* opacity: 0.2; */

  background-image: url('../assets/login.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.logo {
  position: absolute;
  top: 2.5rem;
  left: 3rem;
}
.container {
  display: flex;
}
.container .section {
  width: 50%;
}
/* .container .section__left {
  background-color: var(--mainColor1);
} */
.section__right {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.section__right svg {
  z-index: -1000;
  position: absolute;
  top: 18%;
  right: -10.5rem;
  height: 20rem;
  transform: rotate(40deg);
}
.section__right p {
  position: absolute;
  bottom: 5rem;
  right: 8rem;
  color: var(--fontColor1);
  width: 22rem;
}
.section__right svg rect:first-child {
  fill: var(--grayColor1);
}
.section__left {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.section__left svg {
  position: absolute;
  right: -7.5rem;
  top: 65%;
  height: 15rem;
}
.section__left svg circle:nth-child(2) {
  fill: var(--mainColor1);
}
.section__left > div:nth-child(2) {
  margin: auto;
  width: 350px;
  display: flex;
  flex-flow: column;
  z-index: 2;
}
.section__left .title h2 {
  color: white;
}
.section__left p {
  color: white;
}
.section__left .separator {
  background-color: white;
  width: 5rem;
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}
.logo {
  position: absolute;
  right: 1rem;
}
.form-container {
  position: relative;
  max-width: 350px;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  margin: 0 auto;
  border-radius: 5px;
}
.form-container .title {
  gap: 0.4rem;
  margin-top: 0.5rem;
}
.form-container .title h2 {
  text-align: center;
}
.form-container .title p {
  font-weight: var(--medium);
  color: var(--grayColor2);
  line-height: 1.25;
  font-size: 0.8rem;
}
.form-container span {
  display: flex;
  flex-flow: column;
  gap: 1.2rem;
}
.separator {
  height: 2.5px;
  width: 2rem;
  background-color: var(--mainColor1);
  margin: 0.5rem auto;
  margin-bottom: 2rem;
}
</style>
